import React, { useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalProvider, BaseModalBackground } from 'styled-react-modal';
import { CircularProgress } from '@material-ui/core';
import PageRoot from '../components/common-components/templates/page-root-v2';
import colorPaletteV2 from '../styles/color-palette-v2';
import SEOHeaderV2 from '../components/common-components/templates/page-root-v2/seo-v2';

const ContactForm = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  padding-bottom: 80px;
  padding-bottom: 80px;
  @media (min-width: 960px) {
    display: ${props => (props.isVisible ? 'flex' : 'none')};
    align-items: center;
    flex-direction: column;
    margin-top: 180px;
    margin-top: 180px;
    padding-bottom: 100px;
    padding-bottom: 100px;
  }
  &.confirm-modal-card {
    display: flex;
    flex-direction: column;
    background-color: ${colorPaletteV2.surface};
    padding: 96px 16px;
    padding: 96px 16px;
    height: 100vh;
    overflow: scroll;
    position: absolute;
    padding-bottom: 48px;
    padding-bottom: 48px;
    margin-top: 0;
    margin-top: 0;
    width: 100%;
    @media (min-width: 960px) {
      height: auto;
      overflow: inherit;
      padding: 48px 80px;
      padding: 48px 80px;
      position: relative;
      width: auto;
    }
  }
  .page-title {
    text-align: center;
    font-size: 32px;
    font-size: 32px;
    margin-bottom: 64px;
    margin-bottom: 64px;
    @media (min-width: 960px) {
      font-size: 42px;
      font-size: 42px;
      margin-bottom: 48px;
      margin-bottom: 48px;
    }
    &.confirm-modal-card {
      font-size: 24px;
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 40px;
      margin-bottom: 40px;
    }
  }
  .form-wrap {
    @media (min-width: 960px) {
      display: grid;
      grid-template-columns: 190px 418px;
      grid-template-columns: 190px 418px;
      grid-row-gap: 32px;
      grid-row-gap: 32px;
    }
    &.confirm-modal-card {
      @media (min-width: 960px) {
        grid-row-gap: 16px;
        grid-row-gap: 16px;
      }
    }
  }
  .form-title {
    font-size: 16px;
    font-size: 16px;
    padding-top: 12px;
    padding-top: 12px;
    display: flex;
    align-items: flex-start;
    padding-bottom: 12px;
    padding-bottom: 12px;
    @media (min-width: 960px) {
      padding-bottom: 0;
      padding-bottom: 0;
    }
    .required {
      font-size: 12px;
      font-size: 12px;
      margin-left: 8px;
      margin-left: 8px;
      padding-top: 4px;
      padding-top: 4px;
      color: ${colorPaletteV2.orange};
    }
  }
  .form-content {
    margin-bottom: 32px;
    margin-bottom: 32px;
    @media (min-width: 960px) {
      margin-bottom: 0;
      margin-bottom: 0;
    }
    &.confirm-modal-card {
      font-size: 16px;
      font-size: 16px;
      padding: 8px 12px;
      padding: 8px 12px;
      width: 100%;
      white-space: pre-wrap;
      line-height: 1.5;
      background: ${colorPaletteV2.whitePale};
      &.inquiry {
        @media (min-width: 960px) {
          max-height: 200px;
          max-height: 200px;
          overflow: scroll;
        }
      }
    }
  }
  /* .form-content textarea {
    font-size: 16px;
    font-size: 16px;
    padding: 8px 12px;
    padding: 8px 12px;
    width: 100%;
    border: none;
  } */
  /* form */
  input,
  button,
  textarea,
  select {
    /* デフォルトスタイルをリセット */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* font-familyを継承しないので、継承させる */
    font-family: inherit;

    /* iOSの角丸をリセット */
    border-radius: 0;

    /* box-size */
    box-sizing: border-box;

    /* 文字の大きさ iOSでズームさせないために16px以上を指定 */
    font-size: 16px;
    font-size: 16px;
    padding: 8px 12px;
    padding: 8px 12px;
    width: 100%;
    border-color: ${colorPaletteV2.white};

    /* 文字色を親から継承 */
    color: inherit;
  }

  label {
    /* iOSでのlabelとinput,select,textareaの関連付け */
    cursor: pointer;
  }

  /* スピンボタン非表示 chrome,safari */
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='month']::-webkit-outer-spin-button,
  input[type='month']::-webkit-inner-spin-button,
  input[type='datetime-local']::-webkit-outer-spin-button,
  input[type='datetime-local']::-webkit-inner-spin-button,
  input[type='week']::-webkit-outer-spin-button,
  input[type='week']::-webkit-inner-spin-button,
  input[type='time']::-webkit-outer-spin-button,
  input[type='time']::-webkit-inner-spin-button,
  input[type='date']::-webkit-outer-spin-button,
  input[type='date']::-webkit-inner-spin-button {
    /*-webkit-appearance: none;
  margin: 0;*/
    display: none;
  }

  /* スピンボタン非表示(firefox) */
  input[type='number'],
  input[type='month'],
  input[type='datetime-local'],
  input[type='week'],
  input[type='time'],
  input[type='date'] {
    -moz-appearance: textfield;
  }

  /* クリアボタン非表示 */
  input[type='date']::-webkit-clear-button,
  input[type='month']::-webkit-clear-button,
  input[type='datetime-local']::-webkit-clear-button,
  input[type='time']::-webkit-clear-button,
  input[type='week']::-webkit-clear-button {
    -webkit-appearance: none;
  }

  /* input */
  input {
    /* 背景色(任意の色を指定) */
    background-color: ${colorPaletteV2.white};

    /* inputの枠線を消す */
    border: 1px solid transparent;
    transition: border 0.2s ease-out;

    /* 文字色を親から継承 */
    color: inherit;

    /* 任意の高さ */
    height: 46px;

    /*inputのフォーカス時の枠線を消す*/
    outline: 0;
  }

  /* inputにフォーカスが当たっている時 */
  input:focus {
    border-bottom: 1px solid ${colorPaletteV2.blue};
  }

  input::placeholder,
  textarea::placeholder {
    color: ${colorPaletteV2.grayPale};
  }
`;

const Grad1Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 2px !important;
  padding: 2px !important;
  appearance: none;
  margin: 0 auto;
  margin-top: 40px;
  margin-top: 40px;
  max-width: 400px;
  max-width: 400px;
  &.confirm-modal-card {
    margin-top: 20px;
    margin-top: 20px;
  }
`;

const StyledModal = styled(Modal)`
  opacity: ${props => props.opacity};
  transition: opacity ease 500ms;
  position: relative;
  @media (min-width: 960px) {
    width: 84px;
    height: 100vh;
  }
`;

const SendingCircular = styled(CircularProgress)`
  color: ${colorPaletteV2.blue};
  margin-top: 40px;
  margin-top: 40px;
`;

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition: opacity ease 200ms;
  background: rgba(106, 106, 106, 0.5);
`;

const CloseButton = styled.button`
  width: 80px !important;
  width: 80px !important;
  height: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  background: none !important;
  border-color: ${colorPaletteV2.surface};
  .menuSP-txt {
    font-size: 10px;
    font-size: 10px;
    font-weight: 700;
    position: relative;
    top: 8px;
  }
  .menu-hamburger-lines,
  .menu-hamburger-lines span {
    display: inline-block;
    box-sizing: border-box;
  }
  .menu-hamburger-lines {
    position: relative;
    width: 20px;
    width: 20px;
    height: 20px;
    height: 20px;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
  }
  .menu-hamburger-lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${colorPaletteV2.black};
    border-radius: 2px;
  }
  .menu-hamburger-lines span:nth-of-type(1) {
    top: 2px;
    transform: translateY(10px) rotate(-45deg);
    width: 110%;
  }
  .menu-hamburger-lines span:nth-of-type(2) {
    bottom: 2px;
    transform: translateY(-10px) rotate(45deg);
    top: 22px;
    width: 110%;
  }
`;

const ResultWrapper = styled.div`
  display: ${props => (props.isVisible ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 16px;
  font-size: 16px;
  line-height: 1.5;
  padding: 64px 16px 80px;
  padding: 64px 16px 80px;
  @media (min-width: 960px) {
    align-items: center;
    flex-direction: column;
    margin-top: 32px;
    margin-top: 32px;
    padding-bottom: 100px;
    padding-bottom: 100px;
  }
  .title {
    font-size: 18px;
    font-size: 18px;
    margin-bottom: 24px;
    margin-bottom: 24px;
    font-weight: 700;
  }
  p {
    margin-bottom: 14px;
    margin-bottom: 14px;
  }
  a {
    color: ${colorPaletteV2.blue};
    display: inline-block;
  }
  .back-to-top {
    margin-top: 18px;
    margin-top: 18px;
  }
  @media (min-width: 960px) {
    align-items: center;
    text-align: center;
    top: 20px;
  }
`;

const ContactPageV2 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const [opacity, setOpacity] = useState(0);
  const [inputs, setInputs] = useState(Array(5));
  const [isSending, setIsSending] = useState(false);

  const toggleModal = e => {
    if (inputs[0] && inputs[1] && inputs[2] && inputs[3] && inputs[4]) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  const afterOpen = () => {
    setTimeout(() => {
      setOpacity(1);
    }, 10);
  };

  const beforeClose = () => {
    return new Promise(resolve => {
      setOpacity(0);
      setTimeout(resolve, 200);
    });
  };

  const handleOnChange = (e, index) => {
    const newInputs = inputs;
    newInputs[index] = e.target.value;
    setInputs(newInputs);
  };

  const sendContents = async () => {
    setIsSending(true);
    await fetch(
      'https://9s3xu3biv8.execute-api.us-west-2.amazonaws.com/production',
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subject: `お問い合わせ : ${inputs[1]}様`,
          message: `所属先 : ${inputs[0]}\n名前 : ${inputs[1]}\nメールアドレス : ${inputs[2]}\n電話番号 : ${inputs[3]}\n内容 : ${inputs[4]}`,
        }),
      },
    );
    setIsSending(false);
    setIsVisible(false);
    setIsOpen(!isOpen);
  };

  return (
    <ModalProvider backgroundComponent={FadingBackground}>
      <PageRoot>
        {/* <ContactFormV2
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        /> */}
        <SEOHeaderV2 pageTitle="お問い合わせ" />
        <ContactForm
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          className="wrapper-v2"
        >
          <h1 className="page-title">お問い合わせ</h1>
          <form>
            <dl className="form-wrap">
              <dt className="form-title">
                <label htmlFor="form__input--belongTo">ご所属先</label>
                <span className="required">*必須</span>
              </dt>
              <dd className="form-content">
                <input
                  type="text"
                  name="belongTo"
                  id="form__input--belongTo"
                  placeholder="例）株式会社〇〇"
                  onChange={e => handleOnChange(e, 0)}
                  required
                />
              </dd>
              <dt className="form-title">
                <label htmlFor="form__input--name">お名前</label>
                <span className="required">*必須</span>
              </dt>
              <dd className="form-content">
                <input
                  type="text"
                  name="text"
                  id="form__input--name"
                  placeholder="例）山田 太郎"
                  onChange={e => handleOnChange(e, 1)}
                  required
                />
              </dd>
              <dt className="form-title">
                <label htmlFor="form__input--email">メールアドレス</label>
                <span className="required">*必須</span>
              </dt>
              <dd className="form-content">
                <input
                  type="email"
                  name="email"
                  id="form__input--email"
                  placeholder="例）xxxxx@xxxxx.xxx"
                  onChange={e => handleOnChange(e, 2)}
                  required
                />
              </dd>
              <dt className="form-title">
                <label htmlFor="form__input--tel">電話番号</label>
                <span className="required">*必須</span>
              </dt>
              <dd className="form-content">
                <input
                  type="tel"
                  name="tel"
                  id="form__input--tel"
                  placeholder="例）09012345678"
                  onChange={e => handleOnChange(e, 3)}
                  required
                />
              </dd>
              <dt className="form-title">
                <label htmlFor="form__input--content">お問い合わせ内容</label>
                <span className="required">*必須</span>
              </dt>
              <dd className="form-content">
                <textarea
                  name="comments"
                  rows="10"
                  id="form__input--content"
                  placeholder="例）自由にお書きください"
                  onChange={e => handleOnChange(e, 4)}
                  required
                />
              </dd>
            </dl>
            <Grad1Button
              className="grad1_border_button"
              onClick={e => toggleModal(e)}
            >
              <span className="grad1_border_button_inside">確認画面へ</span>
            </Grad1Button>
          </form>
        </ContactForm>
        <StyledModal
          isOpen={isOpen}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
          opacity={opacity}
          backgroundProps={{ opacity }}
        >
          <ContactForm className="confirm-modal-card">
            <CloseButton onClick={toggleModal}>
              <div className="menu-hamburger-lines active">
                <span />
                <span />
              </div>
              <span className="menuSP-txt">CLOSE</span>
            </CloseButton>

            <h1 className="page-title confirm-modal-card">
              以下の内容で送信します。
              <br />
              よろしいですか？
            </h1>
            <dl className="form-wrap confirm-modal-card">
              <dt className="form-title">
                <label htmlFor="form__input--belongTo">ご所属先</label>
              </dt>
              <dd className="form-content confirm-modal-card">{inputs[0]}</dd>
              <dt className="form-title">
                <label htmlFor="form__input--belongTo">お名前</label>
              </dt>
              <dd className="form-content confirm-modal-card">{inputs[1]}</dd>
              <dt className="form-title">
                <label htmlFor="form__input--belongTo">メールアドレス</label>
              </dt>
              <dd className="form-content confirm-modal-card">{inputs[2]}</dd>
              <dt className="form-title">
                <label htmlFor="form__input--belongTo">電話番号</label>
              </dt>
              <dd className="form-content confirm-modal-card">{inputs[3]}</dd>
              <dt className="form-title">
                <label htmlFor="form__input--belongTo">お問い合わせ内容</label>
              </dt>
              <dd className="form-content confirm-modal-card inquiry">
                {inputs[4]}
              </dd>
            </dl>
            <div>
              {isSending ? (
                <SendingCircular color="inherit" className="sending-circular" />
              ) : (
                <Grad1Button
                  className="grad1_border_button confirm-modal-card"
                  onClick={sendContents}
                >
                  <span className="grad1_border_button_inside">送信</span>
                </Grad1Button>
              )}
            </div>
          </ContactForm>
        </StyledModal>
        <ResultWrapper isVisible={isVisible}>
          <h1 className="title">
            お問い合わせ
            <br className="display_only_SP" />
            ありがとうございました。
          </h1>
          <div>
            <a className="back-to-top" href="/">
              TOPへ戻る
            </a>
          </div>
        </ResultWrapper>
      </PageRoot>
    </ModalProvider>
  );
};

export default ContactPageV2;
